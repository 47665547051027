<!-- 工艺边框 -->
<template>
  <BaseFormItem
    class="overlay_type"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val.overlay_type" @change="handleTypeChange">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <template v-if="val.overlay_type == 3">
      <i class="overlay_type__linker">-</i>
      <BaseInput 
        v-model.trim="val.overlay_seq" 
        width="246px" 
        placeholder="例如：TOP-GND-POWER-BOTTOM"
        @input="handleEmit('overlay_seq')"
        @change="handleEmitChange"
      ></BaseInput>
    </template>
    <span v-if="val.overlay_type == 1" slot="other" class="overlay_type__tips">
      提交订单时可录入工程师联系方式
    </span>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'

export default {
  props: {
    overlay_type: {
      type: Number,
      default: 2
    },
    overlay_seq: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      val: {
        overlay_type: this.overlay_type,
        overlay_seq: this.overlay_seq
      },
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('overlay_type')
    }
  },
  watch: {
    overlay_type(overlay_type) {
      this.val.overlay_type = overlay_type
    },
    overlay_seq(overlay_seq) {
      this.val.overlay_seq = overlay_seq
    }
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.isShowError(false)
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    handleTypeChange() {
      if (this.val.overlay_type != 3) {
        this.val.overlay_seq = ''
        this.handleEmit('overlay_seq')
      }
      this.handleEmit('overlay_type')
      this.handleEmitChange()
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay_type {
  .overlay_type__linker {
    margin: 0 4px;
    color: $--color-text-placeholder;
  }
  .overlay_type__tips {
    font-size: 12px;
    color: $--color-text-placeholder;
  }
}
</style>