<template>
  <div class="fpc-container">
    <FpcQoute parentSite="pcb" />
  </div>
</template>

<script>
import FpcQoute from '@@/views/fpc/index'
export default {
  name: 'ordersFpc',
  components: {
    FpcQoute
  }
}
</script>

<style lang="scss" scoped>
</style>
