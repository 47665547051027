<template>
  <div class="quote-container" v-loading="loading">
    <el-form 
      id="quoteForm" 
      class="quote-container__left" 
      size="small" 
      label-width="120px"
      v-anchorNav="isPcbSite ? {
        dir_basicInfo: '基本信息',
        dir_processInfo: '工艺信息',
        dir_serviceInfo: '服务信息',
      } : {}"
    >
      <!-- 基本信息 -->
      <div id="dir_basicInfo" class="quote-container__content">
        <div class="quote-container__header">
          <p>FPC下单<span>(软板)</span></p>
        </div>
        <!-- 下单必看提示 -->
        <MustSeeTips />
        <!-- SMT贴片 -->
        <is_need_smt v-if="!isPcbSite" ref="is_need_smt" v-model="fpcParams.is_need_smt" :fpcParams="fpcParams" />
        <!-- 板子层数 -->
        <blayer v-model="fpcParams.blayer" />
        <!-- 拼版款数 -->
        <pbnum ref="pbnum" v-model="fpcParams.pbnum" />
        <!-- 出货形式 -->
        <units v-model="fpcParams.units" />
        <!-- 尺寸 -->
        <length ref="length" :length.sync="fpcParams.length" :width.sync="fpcParams.width" :units="fpcParams.units" />
        <!-- 拼版规则 -->
        <layout
          ref="layout"
          v-if="isShow.layout"
          :layoutx.sync="fpcParams.layoutx"
          :layouty.sync="fpcParams.layouty"
          :exampleData="{
            setlength: computePcbSize.length,
            setwidth: computePcbSize.width,
            length: fpcParams.length,
            width: fpcParams.width,
            layouty: fpcParams.layouty,
            layoutx: fpcParams.layoutx,
            bcount: fpcParams.bcount,
            pcsNum: pcsNumForSet,
            m: pcbPrice.m
          }"
        />
        <!-- 数量 -->
        <bcount 
          ref="bcount" 
          v-model="fpcParams.bcount" 
          :units="fpcParams.units" 
          :area="pcbPrice.m"
        />
        <!-- 工艺边框 -->
        <sidewidth ref="sidewidth" v-if="isShow.sidewidth" :sidedirection.sync="fpcParams.sidedirection" :sidewidth.sync="fpcParams.sidewidth" />
      </div>

      <!-- 工艺信息 -->
      <div id="dir_processInfo" class="quote-container__content">
        <i class="quote-container__linker"></i>
        <p class="quote-container__title">工艺信息</p>
        <!-- 材料 -->
        <material v-model="fpcParams.material" :blayer="fpcParams.blayer" :impendance="fpcParams.impendance" :initFpcParamsChange="initFpcParamsChange" />
        <!-- PI 厚度 -->
        <pi_thickness ref="pi_thickness" v-model="fpcParams.pi_thickness" :blayer="fpcParams.blayer" :bheight="fpcParams.bheight" />
        <!-- 板子厚度 -->
        <bheight ref="bheight" v-model="fpcParams.bheight" :blayer="fpcParams.blayer" :initFpcParamsChange="initFpcParamsChange" />
        <!-- 外层铜厚 -->
        <copper v-model="fpcParams.copper" :blayer="fpcParams.blayer" :bheight="fpcParams.bheight" />
        <!-- 内层铜厚 -->
        <insidecopper v-show="isShow.insidecopper" v-model="fpcParams.insidecopper" :blayer="fpcParams.blayer" />
        <!-- 最小线宽/线距 -->
        <lineweight v-model="fpcParams.lineweight" />
        <!-- 最小线宽/线距 -->
        <vias v-model="fpcParams.vias" />
        <!-- 阻焊类型 -->
        <cover v-model="fpcParams.cover" :blayer="fpcParams.blayer" :initFpcParamsChange="initFpcParamsChange" />
        <!-- 阻焊颜色 -->
        <color v-model="fpcParams.color" :cover="fpcParams.cover" />
        <!-- 字符颜色 -->
        <charcolor v-model="fpcParams.charcolor" :color="fpcParams.color" />
        <div class="quote-container__multicolumn">
          <!-- 表面处理 -->
          <spray v-model="fpcParams.spray" />
          <!-- 沉金厚度 -->
          <cjh v-show="isShow.cjh" v-model="fpcParams.cjh" :spray="fpcParams.spray" />
          <!-- 电金厚度 -->
          <electrogilding_thickness v-show="isShow.electrogilding_thickness" v-model="fpcParams.electrogilding_thickness" :spray="fpcParams.spray" />
        </div>
        <!-- 成型方式 -->
        <forming_type v-model="fpcParams.forming_type" />
        <!-- 补强 -->
        <reinforce ref="reinforce" v-model="fpcParams.reinforce" />
        <!-- 补强类型 -->
        <reinforce_type
          ref="reinforce_type"
          v-show="isShow.reinforce_type"
          :reinforce="fpcParams.reinforce"
          :reinforce_steel.sync="fpcParams.reinforce_steel"
          :steel_thickness.sync="fpcParams.steel_thickness"
          :reinforce_aluminium.sync="fpcParams.reinforce_aluminium"
          :aluminium_thickness.sync="fpcParams.aluminium_thickness"
          :reinforce_fr4.sync="fpcParams.reinforce_fr4"
          :fr4_thickness.sync="fpcParams.fr4_thickness"
          :reinforce_fingerpi.sync="fpcParams.reinforce_fingerpi"
          :fingerpi_thickness.sync="fpcParams.fingerpi_thickness"
          :reinforce_otherpi.sync="fpcParams.reinforce_otherpi"
          :otherpi_thickness.sync="fpcParams.otherpi_thickness"
        />
        <!-- 背胶 -->
        <back_gum_type v-model="fpcParams.back_gum_type" />
        <!-- 电磁膜 -->
        <electromagnetic_membrane ref="electromagnetic_membrane" v-model="fpcParams.electromagnetic_membrane" />
        <!-- 导电胶 -->
        <conducting_resin ref="conducting_resin" v-model="fpcParams.conducting_resin" />
        <!-- 阻抗 -->
        <impendance v-model="fpcParams.impendance" />
        <!-- 层压顺序 -->
        <overlay_type 
          ref="overlay_type"
          v-if="isShow.overlay_type"
          :overlay_type.sync="fpcParams.overlay_type"
          :overlay_seq.sync="fpcParams.overlay_seq"
        />
      </div>

      <!-- 服务信息 -->
      <div id="dir_serviceInfo" class="quote-container__content">
        <i class="quote-container__linker"></i>
        <p class="quote-container__title">服务信息</p>
        <!-- 测试方式 -->
        <test ref="test" v-model="fpcParams.test" />
        <!-- 确认生产稿 -->
        <review_file ref="review_file" v-model="fpcParams.review_file" />
        <!-- 产品报告 -->
        <report v-model="fpcParams.report" :impendance="fpcParams.impendance" />
        <!-- 报告形式 -->
        <report_type ref="report_type" v-if="isShow.report_type" v-model="fpcParams.report_type" />
        <!-- 打叉板 -->
        <cross_board ref="cross_board" v-model="fpcParams.cross_board" />
        <!-- SMT贴片 -->
        <is_need_smt 
          v-if="isPcbSite" 
          ref="is_need_smt" 
          v-model="fpcParams.is_need_smt" 
          :fpcParams="fpcParams" 
          @reviewFileChange="val => fpcParams.review_file = val"
        />
      </div>

      <!-- SMT信息 -->
      <div v-if="isNeedSmt" class="quote-container__content">
        <i class="quote-container__linker"></i>
        <p class="quote-container__title">SMT信息</p>
        <SmtForm ref="smtFormRef" :data="smtParams" />
      </div>
    </el-form>

    <Affix :offset="89" relativeContainerId="quoteForm">
      <QuoteRight :type="isNeedSmt ? 'FPC_SMT' : 'FPC'" />
    </Affix>

    <AffixService v-if="isPcbSite" />
  </div>
</template>

<script>
// 下单必看提示
import MustSeeTips from '@@/components/mustSeeTips/index'
// smt信息
import SmtForm from '../pcb/smtForm'
// 右侧固钉悬浮
import Affix from '@@/components/affix/index'
// 计价页右侧
import QuoteRight from '@@/views/quoteRight/index'
// 右侧悬浮专属客服
import AffixService from '../pcb/components/affixService.vue'

import { isPcbSite } from '@@/utils/is'
import { sensorsTrack } from '@@/utils/sensors'
import { debounce } from '@@/utils/utils'
import { scrollTop } from '@@/utils/pageScroll'
import { getNumberOptionKeys } from '@@/utils/getFields'
import { anchorNav } from '@@/utils/directives'
import { ORDER_TYPE_ENUMS } from '@@/utils/constant'
import { FPC_DEFAULT_FORM, SMT_DEFAULT_FORM } from '@@/utils/defaultForm'
import {
  computePcbSize,
  pcsNumForSet
} from '@@/utils/pcbParamsFormat'
import {
  isShowFpcParams,
  formatFpcParams
} from '@@/utils/fpcParamsFormat'
import { formatSmtParams } from '@@/utils/smtParamsFormat'
import { getPcbQuote, getPcbPrice } from '@@/api/pcb'
import { getSmtQuote, getSmtPrice } from '@@/api/smt'

// 自动注册 fields 文件夹下的订单字段组件
const requireFields = require.context('./fields', true, /\.vue$/)
let fieldsComponents = {}
requireFields.keys().forEach(fileName => {
  let component = requireFields(fileName)
  fieldsComponents[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = component.default
})

export default {
  components: {
    MustSeeTips,
    SmtForm,
    Affix,
    QuoteRight,
    AffixService,
    ...fieldsComponents
  },
  directives: {
    anchorNav
  },
  data() {
    return {
      loading: false,
      fpcParams: { ...FPC_DEFAULT_FORM },
      smtParams: { ...SMT_DEFAULT_FORM, order_info: { ...SMT_DEFAULT_FORM.order_info }, custom_pcb_ban: 1 },
      pcbPrice: {}, // PCB价格
      smtPrice: {}, // SMT价格
      priceTotal: {}, // 订单总计价格
      testOptions: [],
      debounceGetPirce: debounce(this.getPrice, 300), // 计价接口防抖
      initFpcParamsChange: false, // 返回修改初始化参数时，参数不进行关联变更
    }
  },
  // 向右侧侧边栏注入订单参数
  provide() {
    return {
      fpcParams: this.fpcParams,
      smtParams: this.smtParams,
      getPcbPrice: () => this.pcbPrice,
      getSmtPrice: () => this.smtPrice,
      getPriceTotal: () => this.priceTotal,
      fpcValidate: valids => this.validate(valids),
      smtValidate: valids => this.smtValidate(valids)
    }
  },
  computed: {
    // PCB站点
    isPcbSite() {
      return isPcbSite
    },
    // 相关字段是否显示判断
    isShow() {
      return isShowFpcParams(this.fpcParams)
    },
    // 是否需要SMT贴片
    isNeedSmt() {
      // return this.fpcParams.is_need_smt === 1
      // PCB下单与【smt贴片】字段不再交互
      return false
    },
    // 计算板子尺寸
    computePcbSize() {
      return computePcbSize(this.fpcParams)
    },
    // 连片数量换算为单片数量
    pcsNumForSet() {
      return pcsNumForSet(this.fpcParams)
    }
  },
  watch: {
    fpcParams: {
      deep: true,
      handler: function (val) {
        this.debounceGetPirce()
      }
    },
    smtParams: {
      deep: true,
      handler: function (val) {
        this.debounceGetPirce()
      }
    }
  },
  mounted() {
    let { id, type = 'FPC' } = this.$route.query
    type = isNaN(Number(type)) ? type : ORDER_TYPE_ENUMS[type]
    if (id) {
      this.fpcParams.is_need_smt = type.includes('SMT') ? 1 : 2
      this.getParams(id)
    } else {
      this.initUrlParams()
    }

    // 神策数据埋点
    sensorsTrack('FPC1_quotation_order_view', { entrance: '新版FPC计价页面浏览' })
  },
  methods: {
    // 填充url参数
    initUrlParams() {
      const routeQuery = this.$route.query
      // 需要添加 b 前缀的key
      const otherUrlParamKeys = ['count', 'layer', 'height']
      const urlParamKeys = ['length', 'width', 'blayer', 'bcount', 'bheight', 'lineweight', ...otherUrlParamKeys]

      if (!urlParamKeys.some(key => routeQuery[key])) return

      // 当url有带参数时，填充参数
      this.initFpcParamsChange = true
      const urlData = urlParamKeys.reduce((data, key) => {
        const val = routeQuery[key]
        const paramKey = otherUrlParamKeys.includes(key) ? `b${key}` : key
        data[paramKey] = val ? +val : FPC_DEFAULT_FORM[paramKey]
        return data
      }, {})
      this.fpcParams = Object.assign(this.fpcParams, urlData)
      this.$nextTick(() => {
        this.initFpcParamsChange = false
      })
    },
    // 需要转为 Number 类型的字段
    transformNumberFields(type, params) {
      const fields = getNumberOptionKeys(type)
      return fields.reduce((data, key) => {
        if (params[key] !== undefined) {
          data[key] = Number(params[key])
        }
        return data
      }, {})
    },
    // FPC参数处理
    initFpcParams(params) {
      this.initFpcParamsChange = true
      const transformNumberFields = this.transformNumberFields('FPC', params)
      // 补强类型
      const reinforceFields = ['reinforce_steel', 'reinforce_aluminium', 'reinforce_fr4', 'reinforce_fingerpi', 'reinforce_otherpi']
      this.fpcParams = Object.assign(this.fpcParams, params, transformNumberFields, {
        // 补强
        reinforce: reinforceFields.some(i => params[i] == 1) ? 1 : 0,
        // 产品报告
        report: params.report ? params.report.split(',') : ['无需']
      })
      this.$nextTick(() => {
        this.initFpcParamsChange = false
      })
    },
    // SMT参数处理
    initSmtParams(params) {
      const { smt_params, order_info = {} } = params
      const transformNumberFields = this.transformNumberFields('SMT', smt_params)
      this.smtParams = Object.assign(this.smtParams, smt_params, transformNumberFields, { order_info: {...order_info} })
    },
    // 获取订单信息
    async getParams(id) {
      try {
        this.loading = true
        this.isNeedSmt ? await this.getSmtParams(id) : await this.getPcbParams(id)
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 获取PCB订单信息
    async getPcbParams(quoteid) {
      return await getPcbQuote({ quoteid })
        .then(res => {
          if (!res) return false
          const { retCode, retMsg, result } = res
          if (retCode === 0) {
            const { quote, extend, notify } = result
            const params = { ...extend, ...notify, ...quote, quoteid }
            this.initFpcParams(params)
          } else {
            this.$message.error(retMsg)
          }
        })
    },
    // 获取SMT订单信息
    async getSmtParams(smtTmpId) {
      return await getSmtQuote({ smtTmpId })
        .then(res => {
          if (!res) return false
          const { suc, body, msg } = res
          if (suc) {
            const { pcb_params, ...smt_params } = body
            // PCB参数处理
            this.initFpcParams(pcb_params)
            // SMT参数处理
            this.initSmtParams(smt_params)
          } else {
            this.$message.error(msg)
          }
        })
    },

    // 计价
    async getPrice() {
      const { length, width, bcount } = this.fpcParams
      // 尺寸、数量没填时不能计价
      if (!length || !width || !bcount) return
      // 需要SMT贴片时，调用SMT计价接口 否则调用PCB计价接口
      const res = this.isNeedSmt ? await this.getSmtPrice() : await this.getPcbPrice()

      const { detail = {} } = res
      const { pcb = {}, smt = {} } = detail
      const { suggest = {}, deltime, test, manufacture } = pcb

      this.pcbPrice = pcb
      this.smtPrice = smt
      this.priceTotal = res

      // 是否时外发
      this.manufacture = manufacture

      // 测试方式选项
      this.testOptions = suggest.test
      this.fpcParams.test = test

      // 生产周期
      this.fpcParams.deltime = deltime

    },
    // PCB 计价
    async getPcbPrice() {
      // want_bonus_activity：获取优惠券列表
      const params = formatFpcParams(this.fpcParams, { want_bonus_activity: 1 })
      return await getPcbPrice(params)
        .then(res => {
          if (!res) return {}
          const { retCode, result } = res
          return retCode === 0 ? result : {}
        })
    },
    // SMT + PCB 计价
    async getSmtPrice() {
      const { fpcParams, smtParams } = this
      return await getSmtPrice({ pcb_params: formatFpcParams(fpcParams), ...formatSmtParams(smtParams) })
        .then(res => {
          if (!res) return {}
          const { suc, body } = res
          return suc ? body : {}
        })
    },

    scrollIntoView(key) {
      if (this.$refs[key]) {
        // 滚动到元素上面偏移50px的位置
        const top = this.$refs[key].$el.getBoundingClientRect().top
        scrollTop(top, -50)
      }
    },
    validate(data) {
      data.forEach((item, index) => {
        const ref = this.$refs[item.key]
        if (ref && ref.isShowError) {
          // 显示错误提示样式
          ref.isShowError(true, index === 0)
        }
      })
      const fristItem = data[0]
      this.$message.closeAll()
      this.$message({
        type: 'error',
        offset: 400,
        message: fristItem.tips
      })
      this.scrollIntoView(fristItem.key)
    },
    smtValidate(data) {
      this.$nextTick(() => {
        this.$refs.smtFormRef && this.$refs.smtFormRef.validate(data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@@/styles/quote-container.scss';
</style>
<style lang="scss">
@import '~@@/styles/directives.scss';
</style>
