<!-- 拼版款数 -->
<template>
  <BaseFormItem
    class="pbnum"
    :label="fields.label"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseInput
      v-model="val"
      width="130px"
      placeholder="拼版款数"
      @input="val => $emit('input', val)"
      @change="val => $emit('change', val)"
    ></BaseInput>
    <span class="pbnum__tips">指文件内有多少款不同的板子</span>
    <!-- 示例 -->
    <PbnumExample slot="other"></PbnumExample>
  </BaseFormItem>
</template>

<script>
import PbnumExample from '../../pcb/components/pbnumExample'

import { getFPCFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 1
    }
  },
  components: {
    PbnumExample
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('pbnum')
    },
  },
  watch: {
    value(newVal) {
      this.val = newVal
      this.isShowError(false)
    }
  },
  methods: {
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.pbnum {
  .pbnum__tips {
    margin-left: 20px;
    color: $--color-text-placeholder;
  }
}
</style>