<!-- 阻焊类型 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: '覆盖膜双面'
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 返回修改初始化参数时，参数不进行关联变更
    initFpcParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFPCFields('cover')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer(newVal, oldVal) {
      let options = []
      // 当切换到1层板且阻焊类型不是单面时，默认到 覆盖膜单面
      // 当从1层板切换到别的板子层数且不是双面时，默认到 覆盖膜双面
      if (newVal == 1) {
        options = ['覆盖膜单面', '油墨阻焊单面']
      } else if (oldVal == 1) {
        options = ['覆盖膜双面', '油墨阻焊双面']
      }
      if (options.length > 0 && !options.includes(this.val)) {
        this.changeVal(options[0])
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      if (this.initFpcParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>