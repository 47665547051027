<!-- 单片数量 -->
<template>
  <BaseFormItem
    class="bcount"  
    isShowRequiredIcon
    :unit="unit"
    :label="label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseSelect 
      ref="bcountSelect"
      v-model="val" 
      popper-class="bcount__select" 
      width="191px"
      placeholder="请选择"
      @change="handleEmit"
    >
      <el-option
        v-for="item in fields.options"
        :key="item"
        :label="item"
        :value="item">
      </el-option>
      <div class="bcount__select__other">
        <p>
          其它数量：
          <span>数量x长x宽需大于1㎡)</span>
        </p>
        <div>
          <BaseInput 
            v-model="otherVal" 
            size="mini" 
            width="150px"
            @keyup.enter.native="handleUseOther"
          ></BaseInput>
          <BaseButton size="mini" @click="handleUseOther">确定</BaseButton>
        </div>
      </div>
    </BaseSelect>

    <div slot="other">
      <span v-if="area" class="bcount__area">
        （订单面积 {{(area / 10000).toFixed(4)}}㎡）
      </span>
    </div>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    // 出货形式
    units: {
      type: Number,
      default: 1
    },
    // 板子面积
    area: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      val: this.value,
      otherVal: '',
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('bcount')
    },
    unit() {
      return this.units == 1 ? 'Pcs':'Set'
    },
    label() {
      return this.units == 1 ? '单片数量':'连片数量'
    },
    fillTips() {
      return !this.val ? '请选择' : ''
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      this.$emit('input', this.val)
      this.$emit('change', this.val)
      this.isShowError(false)
    },
    handleUseOther() {
      const { otherVal } = this
      if (otherVal > 0) {
        this.val = +otherVal
        this.otherVal = ''
        this.handleEmit()
        this.$refs.bcountSelect.getRefs().blur()
      } else {
        this.$message.warning('请输入其它数量')
      }
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss">
.bcount {
  .bcount__pcsnum {
    margin: 0 4px;
    color: $--color-primary;
  }
  .bcount__area {
    color: $--color-text-placeholder;
  }
}
</style>
<style lang="scss">
.bcount__select {
  .el-select-dropdown__wrap {
    min-height: 300px;
  }
  .el-select-dropdown__list {
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    width: 300px;
    .el-select-dropdown__item {
      padding: 0 2px 0 7px;
      width: 20%;
      height: 26px;
      line-height: 26px;
    }
  }
  .bcount__select__other {
    padding: 0 7px;
    >p {
      margin-bottom: 6px;
      color: $--color-text-secondary;
      >span {
        color: $--color-text-placeholder;
      }
    }
    >div {
      .el-button {
        margin-left: 4px;
      }
    }
  }
}
</style>