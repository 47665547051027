<!-- 尺寸 -->
<template>
  <BaseFormItem
    class="length"
    isShowRequiredIcon
    :unit="fields.unit"
    :label="label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseInput
      v-model="val.length"
      :maxlength="5"
      width="84px"
      placeholder="长/x"
      @input="val => $emit('update:length', val)"
      @change="handleEmitChange"
    ></BaseInput>
    <span class="length__linker">x</span>
    <BaseInput
      v-model="val.width"
      :maxlength="5"
      width="84px"
      placeholder="宽/y"
      @input="val => $emit('update:width', val)"
      @change="handleEmitChange"
    ></BaseInput>
    <img slot="other" :src="require('@@/assets/img/pcb/size_view.jpg')" />
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'

export default {
  props: {
    length: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: [String, Number],
      default: ''
    },
    // 出货形式
    units: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      val: {
        length: this.length,
        width: this.width
      },
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('length')
    },
    label() {
      return this.units == 3 ? '连片尺寸' : '单片尺寸'
    },
    // 是否显示未填写样式
    fillTips() {
      const { length, width } = this.val
      return !length && !width ? '请填写' : ''
    }
  },
  watch: {
    length(length) {
      this.val.length = length
    },
    width(width) {
      this.val.width = width
    }
  },
  methods: {
    handleEmitChange() {
      this.$emit('change', { ...this.val })
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.length {
  .length__linker {
    margin: 0 8px;
    color: #BBBBBB;
  }
}
</style>