<!-- 产品报告 -->
<template>
  <BaseFormItem
    class="report"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseCheckboxGroup v-model="val" @change="handleChange">
      <BaseTipPopover 
        v-for="item in options"
        :key="item.value" 
        :content="item.tips" 
        :disabled="!item.tips"
      >
        <el-checkbox 
          border
          slot="reference"
          :label="item.value"
          :class="{'report__hideCheckbox': item.value === '无需'}"
        >{{ item.label }}</el-checkbox>
      </BaseTipPopover>
    </BaseCheckboxGroup>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: Array,
      default: []
    },
    // 阻抗
    impendance: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFPCFields('report')
    },
    options() {
      const { fields, impendance } = this
      const options = fields.options.filter(item => item.value == '阻抗报告' ? impendance == 1 : true )
      
      return options
    },
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    changeVal(val) {
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    handleChange(value) {
      this.val = !value || value.length === 0 || (value.length > 1 && value.indexOf('无需') > 0) 
        ? ['无需'] 
        : value.filter(item => item !== '无需')
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss">
.report {
  .report__hideCheckbox {
    .el-checkbox__input {
      display: none;
    }
  }
}
</style>