<!-- 补强类型 -->
<template>
  <BaseFormItem
    class="reinforce_type"
    isShowRequiredIcon
    :label="fields.label"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <div class="reinforce_type__content">
      <el-table 
        size="mini"
        class="reinforce_type__table"
        style="width: 280px" 
        :data="tableData" 
      >
        <!-- 选择 -->
        <el-table-column prop="date" label="选择" width="50px">
          <template slot-scope="scope">
            <BaseCheckbox
              v-model="val[scope.row.check]"
              :true-label="1"
              :false-label="0"
              @change="val => handleEmit(scope.row.check)"
            ></BaseCheckbox>
          </template>
        </el-table-column>
        <!-- 补强类型 -->
        <el-table-column prop="label" label="补强类型" width="90px" />
        <!-- 厚度 -->
        <el-table-column prop="address" label="厚度(mm)">
          <template slot-scope="scope">
            <BaseSelect 
              v-if="scope.row.options" 
              size="mini"
              width="100%"
              v-model="val[scope.row.thickness]" 
              placeholder="请选择"
              @change="val => handleEmit(scope.row.thickness)"
            >
              <el-option
                v-for="item in scope.row.options"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </BaseSelect>
            <span v-else>----</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'

export const reinforceTypeDefaultVal = {
  reinforce_steel: '', // 钢片补强，0否、1是
  steel_thickness: 0.1, // 钢片补强厚度
  reinforce_aluminium: '', // 铝片补强，0否、1是
  aluminium_thickness: 0.3, // 铝片补强厚度
  reinforce_fr4: '', // FR-4补强，0否、1是
  fr4_thickness: 0.1, // FR-4补强厚度
  reinforce_fingerpi: '', // 手指位PI补强，0否、1是
  fingerpi_thickness: 0.1, // 手指位PI补强厚度
  reinforce_otherpi: '', // 其他位PI补强，0否、1是
  otherpi_thickness: 0.05, // 其他位PI补强厚度
}

export default {
  props: {
    // 补强
    reinforce: {
      type: [String, Number],
      default: ''
    },
    ...Object.entries(reinforceTypeDefaultVal).reduce((obj, [key, defaultVal]) => {
      obj[key] = { type: [String, Number], default: defaultVal }
      return obj
    }, {})
  },
  data() {
    return {
      val: { ...reinforceTypeDefaultVal },
      tableData: [
        { label: '钢片', check: 'reinforce_steel', thickness: 'steel_thickness', options: [0.1, 0.15, 0.2, 0.25, 0.3, 0.4, 0.5] },
        { label: '铝片', check: 'reinforce_aluminium', thickness: 'aluminium_thickness', options: [0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2] },
        { label: 'FR-4', check: 'reinforce_fr4', thickness: 'fr4_thickness', options: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 1.0, 1.2, 1.5] },
        { label: '手指位PI', check: 'reinforce_fingerpi', thickness: 'fingerpi_thickness', options: [0.1, 0.15, 0.2, 0.225, 0.25] },
        { label: '其他位置PI', check: 'reinforce_otherpi', thickness: 'otherpi_thickness', options: [0.05, 0.1, 0.15, 0.175, 0.2, 0.225, 0.25] }
      ],
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('reinforce_type')
    }
  },
  watch: {
    reinforce(reinforce) {
      // 当不需要补强时，初始化补强类型
      if (reinforce == 0) {
        this.val = { ...reinforceTypeDefaultVal }
        Object.keys(reinforceTypeDefaultVal).forEach(key => {
          this.handleEmit(key)
        })
      }
    },
    ...Object.keys(reinforceTypeDefaultVal).reduce((obj, key) => {
      obj[key] = function(val) {
        this.val[key] = +val
      }
      return obj
    }, {})
  },
  created() {
    Object.keys(reinforceTypeDefaultVal).forEach(key => {
      this.val[key] = this.$props[key] || reinforceTypeDefaultVal[key]
    })
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
      this.isShowError(false)
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.reinforce_type {
  .reinforce_type__content {
    padding: 5px 5px 0;
    border: $--border-base;
    border-radius: $--border-radius-base;
  }
  .reinforce_type__table ::v-deep {
    color: $--color-text-primary;
    &::before {
      background-color: #fff;
    }
    .el-table__header {
      .el-table__cell {
        padding: 0;
        border-bottom: 0;
        background-color: $--background-base;
      }
    }
    .el-table__body {
      .el-table__cell {
        border-bottom-style: dashed;
      }
    }
  }
}
</style>