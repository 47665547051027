<!-- 字符颜色 -->
<template>
  <BaseFormItem
    :label="fields.label"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: '白色'
    },
    // 阻焊颜色
    color: {
      type: String,
      default: '黄色'
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFPCFields('charcolor')
    },
    options() {
      const { val, fields, color } = this
      let options = fields.options
      // 阻焊颜色 === 银色 || 白色 时，禁用白色，否则禁用黑色
      const disabledOptions = ['银色', '白色'].includes(color) ? ['白色'] : ['黑色']

      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      }
      
      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value) }))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>