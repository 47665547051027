<!-- 外层铜厚 -->
<template>
  <BaseFormItem
    :label="fields.label"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <img slot="other" :src="require('@@/assets/img/pcb/copper_view.jpg')" />
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: [String, Number],
      default: '0.33'
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 板子厚度
    bheight: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFPCFields('copper')
    },
    options() {
      const { val, fields, blayer, bheight } = this
      let options = [...fields.options]

      let disabledOptions = []
      // 板子厚度 <= 0.13
      // 板子层数 == 3 且 板子厚度 < 0.3
      // 板子层数 == 4 5 6 且 板子厚度 < 0.35
      if (bheight && (bheight <= 0.13 || (bheight < 0.3 && blayer == 3) || (bheight < 0.35 && [4, 5, 6].includes(blayer)))) {
        disabledOptions = ['1']
      }
      
      // 如果当前项被禁用，选中第一个可选的选项
      if (disabledOptions.includes(val)) {
        this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      }

      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value)}))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>