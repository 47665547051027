<!-- 拼版规则 -->
<template>
  <BaseFormItem
    unit="Pcs"
    class="layout"
    :label="fields.label"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseInput
      v-model="val.layoutx"
      width="84px"
      placeholder="横片数量"
      @input="val => $emit('update:layoutx', val)"
      @change="handleEmitChange"
    ></BaseInput>
    <span class="layout__linker">x</span>
    <BaseInput
      v-model="val.layouty"
      width="84px"
      placeholder="纵片数量"
      @input="val => $emit('update:layouty', val)"
      @change="handleEmitChange"
    ></BaseInput>
    <!-- 示例 -->
    <LayoutExample
      slot="other"
      :data="exampleData" 
    />
  </BaseFormItem>
</template>

<script>
import LayoutExample from '@@/views/pcb/components/layoutExample'

import { getFPCFields } from '@@/utils/getFields'

export default {
  props: {
    layoutx: {
      type: [String, Number],
      default: ''
    },
    layouty: {
      type: [String, Number],
      default: ''
    },
    exampleData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    LayoutExample
  },
  data() {
    return {
      val: {
        layoutx: this.layoutx,
        layouty: this.layouty
      },
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('layout')
    },
    // 是否显示未填写样式
    fillTips() {
      const { layoutx, layouty } = this.val
      return !layoutx && !layouty ? '请填写' : ''
    }
  },
  watch: {
    layoutx(layoutx) {
      this.val.layoutx = layoutx
    },
    layouty(layouty) {
      this.val.layouty = layouty
    }
  },
  methods: {
    handleEmitChange() {
      const { layoutx, layouty } = this.val
      this.$emit('change', { layoutx, layouty })
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  .layout__linker {
    margin: 0 8px;
    color: #BBBBBB;
  }
}
</style>