<!-- 阻焊颜色 -->
<template>
  <BaseFormItem
    class="color"
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
        :style="colors[item.value]"
      >
        <ColorBlock v-if="item.value !== '无'" :color="item.value" />
        {{ item.label }}
      </el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import ColorBlock from '@@/components/colorBlock/index'

import { COLORS } from '@@/utils/constant'
import { getFPCFields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: '黄色'
    },
    // 阻焊类型
    cover: {
      type: String,
      default: '覆盖膜双面'
    },
  },
  components: {
    ColorBlock
  },
  data() {
    return {
      val: this.value,
      colors: COLORS
    }
  },
  computed: {
    fields() {
      return getFPCFields('color')
    },
    options() {
      const { val, fields, cover } = this
      const options1 = ['黄色', '黑色', '白色', '无']
      const options2 = ['绿色', '黄色', '红色', '橙色', '黑色', '银色', '无']
      // 阻焊颜色与阻焊类型的对应关系
      const options = {
        '覆盖膜单面': options1, 
        '覆盖膜双面': options1,
        '油墨阻焊单面': options2, 
        '油墨阻焊双面': options2
      }[cover] || []

      if (!options.includes(val)) {
        this.changeVal(options[0])
      }
      
      return fields.options.filter(item => options.includes(item.value))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    },
    changeVal(val) {
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  .base-radio-group ::v-deep .el-radio-button {
    // 选中状态
    $--radio-checked-font-color: var(--color);
    $--radio-checked-border-color: var(--border-color);
    $--radio-checked-background-color: var(--background);
    // hover状态
    $--radio-hover-font-color: var(--color);
    $--radio-hover-border-color: var(--border-color);
    .el-radio-button__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    // 选中
    &.is-active {
      .el-radio-button__inner {
        color: $--radio-checked-font-color;
        border-color: $--radio-checked-border-color;
        background: $--radio-checked-background-color;
        box-shadow: none;
        // box-shadow: 0 0 3px $--radio-border-color;
      }
    }
    // hover
    &:not(.is-disabled):not(.is-active):hover {
      .el-radio-button__inner {
        color: $--radio-hover-font-color;
        border-color: $--radio-hover-border-color;
        background: $--radio-hover-background-color;
      }
    }
    // 左边框
    &:not(:first-child) {
      border-left: 1px solid transparent;
      margin-left: -1px;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      &.is-active:not(.is-disabled),
      &:not(.is-disabled):hover {
        border-left-color: $--radio-checked-border-color;
      }
    }
  }
}
</style>