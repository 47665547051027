<!-- SMT贴片 -->
<template>
  <BaseFormItem
    ref="isNeedSmtRef"
    class="is_need_smt"
    isShowRequiredIcon
    :class="{ 'is-pcb-site': isPcbSite }"
    :label="fields.label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <!-- 华秋贴片优势弹框 -->
    <IsNeedSmtDialog slot="other" />
    <!-- 是否需要确认生产稿弹框 -->
    <IsNeedSmtReviewFileDialog 
      ref="isNeedSmtReviewFileDialogRef"
      @change="val => $emit('reviewFileChange', val)"
    />
  </BaseFormItem>
</template>

<script>
import IsNeedSmtDialog from '../../pcb/components/isNeedSmtDialog'
import IsNeedSmtReviewFileDialog from '../../pcb/components/isNeedSmtReviewFileDialog'

import { PCB_PARAMS_SESSION_KEY } from '@@/utils/constant'
import { getFPCFields } from '@@/utils/getFields'
import { targetSelfWindow } from '@@/utils/triggerParent'
import { isPcbSite } from '@@/utils/is'

export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    fpcParams: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    IsNeedSmtDialog,
    IsNeedSmtReviewFileDialog
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('is_need_smt')
    },
    // 是否显示未填写样式
    fillTips() {
      const val = this.val
      return !val ? '请选择' : ''
    },
    // PCB站点
    isPcbSite() {
      return isPcbSite
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
  },
  methods: {
    handleEmit() {
      const val = this.val
      // PCB站点
      if (this.isPcbSite) {
        this.$emit('input', val)
        this.$emit('change', val)

        if (val == 1 && ![1, 2].includes(+this.fpcParams.review_file)) {
          this.$refs.isNeedSmtReviewFileDialogRef.open()
        }
      } else {
        // 用户中心站点
        // 需要
        if (val == 1) {
          this.$confirm('需要SMT，可前往一站式下单页，PCB和SMT一起下单', '温馨提示', {
            center: true,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            customClass: 'is_need_smt__dialog',
            confirmButtonText: '先下PCB订单',
            cancelButtonText: '去一站式下单',
          }).then(() => {
            this.val = 1
            this.$emit('input', 1)
            this.$emit('change', 1)
          }).catch(() => {
            sessionStorage.setItem(PCB_PARAMS_SESSION_KEY, JSON.stringify({...this.fpcParams, is_need_smt: 1}))
            targetSelfWindow({ path: '/orders/smt/quote', query: { pcb_board_type: 'FPC' } })
          })
        } else {
          this.$emit('input', val)
          this.$emit('change', val)
        }
      }
      this.isShowError(false)
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
.is_need_smt {
  position: relative;
  padding: 10px 0 18px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    display: block;
    border-top: 1px dashed $--border-color-base;
  }
  &.is-pcb-site {
    padding: 18px 0 10px;
    &::before {
      top: 0;
      bottom: unset;
    }
  }
}
</style>
<style lang="scss">
.is_need_smt__dialog.el-message-box {
  .el-message-box__btns .el-button {
    &.el-button--default {
      color: #fff;
      background: $--color-primary;
      border-color: $--color-primary;
    }
    &.el-button--primary {
      color: #606266;
      background: #fff;
      border-color: #DCDFE6;
      &:hover {
        color: $--color-primary;
        border-color: $--color-primary;
      }
    }
  }
}
</style>