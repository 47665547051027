<!-- 材料 -->
<template>
  <BaseFormItem
    :label="fields.label"
    :labelTips="fields.labelTips"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 阻抗
    impendance: {
      type: Number,
      default: 0
    },
    // 初始化参数时，参数不进行关联变更
    initFpcParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFPCFields('material')
    },
    options() {
      const { val, fields, impendance, blayer } = this
      let options = [...fields.options]

      let disabledOptions = []
      // 阻抗 == 有
      // 板子层数 == 3, 4, 5, 6
      if (impendance == 1 || [3, 4, 5, 6].includes(blayer)) {
        disabledOptions = ['有胶电解', '有胶压延']
      }
      
      // 如果当前项被禁用，选中第一个可选的选项
      // if (disabledOptions.includes(val)) {
      //   this.changeVal(options.find(item => !disabledOptions.includes(item.value)).value)
      // }

      return options.map(item => ({...item, disabled: disabledOptions.includes(item.value)}))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer() {
      this.changeVal()
    },
    impendance() {
      this.changeVal()
    },
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    changeVal() {
      if (this.initFpcParamsChange) return
      const val = this.options.find(item => !item.disabled).value
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>