<!-- 内层铜厚 -->
<template>
  <BaseFormItem
    :label="fields.label"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFPCFields('insidecopper')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer(blayer) {
      // 板子层数小于等于 2 时，隐藏内层铜厚，且值置为 0
      if (blayer <= 2) {
        this.val = 0
      } else {
        // 板子层数大于 2 时，内层铜厚默认选中 0.33
        this.val = this.val || '0.33'
      }
      this.handleEmit()
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>