<!-- 沉金厚度 -->
<template>
  <BaseFormItem
    :label="fields.label"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        :disabled="item.disabled"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'

export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    spray: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    fields() {
      return getFPCFields('cjh')
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    spray(spray) {
      // 表面处理为 沉金 时，默认选中 1
      if (spray === '沉金' && !this.val) {
        this.val = 1
        this.handleEmit()
      }
    }
  },
  methods: {
    handleEmit() {
      const { val, value } = this
      this.$emit('input', val)
      if (val != value) {
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>