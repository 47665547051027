<!-- 板子厚度 -->
<template>
  <BaseFormItem
    isShowRequiredIcon
    :label="fields.label"
    :labelTips="fields.labelTips"
    :fillTips="fillTips"
    :isError="isError"
    :isErrorBlink="isErrorBlink"
  >
    <BaseRadioGroup v-model="val" @change="handleEmit">
      <el-radio-button
        v-for="item in fields.options"
        :key="item.value"
        :label="item.value"
        :disabled="!canSelectOptions.includes(item.value)"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
  </BaseFormItem>
</template>

<script>
import { getFPCFields } from '@@/utils/getFields'
import { alertFieldsTips } from '@@/utils/alertFieldsTips'

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    // 板子层数
    blayer: {
      type: Number,
      default: 2
    },
    // 初始化参数时，参数不进行关联变更
    initFpcParamsChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.value,
      isError: false, // 是否显示错误提示样式
      isErrorBlink: false, // 是否显示错误提示样式且闪两下
    }
  },
  computed: {
    fields() {
      return getFPCFields('bheight')
    },
    // 是否显示未填写样式
    fillTips() {
      return !this.val ? '请选择' : ''
    },
    canSelectOptions() {
      const { blayer } = this

      const options2 = [0.2, 0.25, 0.3, 0.35]
      const options3 = [0.3, 0.35, 0.4, 0.45]
      const options4 = [0.3]
      // 板子层数与板子厚度的可选项关系
      return { 
        1: [0.1],
        2: [0.1, 0.13, 0.2],
        3: options2,
        4: options2,
        5: options3,
        6: options3,
        8: options4,
        10: options4,
        12: options4
      }[blayer]
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    blayer(blayer) {
      const { val, canSelectOptions } = this
      if (!canSelectOptions.includes(val)) {
        this.changeVal(canSelectOptions[0])
      }
    }
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
      this.isShowError(false)
    },
    changeVal(val) {
      if (this.initFpcParamsChange) return
      alertFieldsTips(this.fields.label)
      this.val = val
      this.handleEmit()
    },
    // 是否显示错误提示样式
    isShowError(isShow, isBlink = false) {
      this.isError = isShow
      this.isErrorBlink = isBlink
    }
  }
}
</script>

<style lang="scss" scoped>
</style>